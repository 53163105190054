import {inject} from "aurelia-framework";
import {Client} from "../../../api/client";
import {DialogController} from 'aurelia-dialog';
import {FlashService} from "../../../flash/flash-service";
import moment from 'moment-timezone';
import * as _ from "lodash";
import {FormServiceFactory} from "../../../form/service/form-service-factory";
import {ConfigurationLoader} from "../../../form/loader/configuration-loader";

@inject(Client, DialogController, FlashService, ConfigurationLoader, FormServiceFactory)
export class RouteSearch {

    nonstop = false;

    constructor(client, dialogController, flash, configurationLoader, formServiceFactory) {
        this.client = client;
        this.dialogController = dialogController;
        this.flash = flash;
        this.configurationLoader = configurationLoader;
        this.formServiceFactory = formServiceFactory;
    }

    onSelectionChangedCallback() {
        return (selectedItems) => {
            this.selectedItems = selectedItems;
        }
    }

    onSelectionReturnChangedCallback() {
        return (selectedItems) => {
            this.returnItems = selectedItems;
        }
    }

    async activate(context) {
        console.log('context', context);
        this.data = context.data;
        this.order = context.order;
        this.participants = context.participants;

        let data = {
            participants: context.participants
        };

        let config = await this.configurationLoader.get('tourism-bus-routing/fare-search', data);

        this.formService = this.formServiceFactory.getFormService(config, _.cloneDeep(data));
        this.formService.changeCallback = this.formValueChanged.bind(this);

        this._calculateParticipantCount();

        this.formValueChanged();
        this.selectedItems = [];
    }

    formValueChanged() {
        let value = this.formService.getValue();
        this.nonStop = value.nonStop;
    }

    _calculateParticipantCount() {
        let adultCount = 0;
        let childCount = 0;
        let infantCount = 0;

        let lastDepartureDate = null;

        let value = this.formService.getValue();


        if (value.itinerary.departureDate) {
            lastDepartureDate = value.itinerary.departureDate;
        }


        for (let participant of this.participants) {

            let birthdate = participant.customer && participant.customer.birthdate ? participant.customer.birthdate : null;

            if (birthdate) {
                let years = moment(lastDepartureDate).diff(moment(birthdate), 'years');

                if (years < 2) {
                    infantCount++;
                } else if (years < 12) {
                    childCount++;
                } else {
                    adultCount++;
                }
            } else {
                adultCount++;
            }
        }

        this.infantCount = infantCount;
        this.childCount = childCount;
        this.adultCount = adultCount;
    }

    changedDepartureDate() {
        this._calculateParticipantCount();
    }

    async search() {
        this.loading = true;

        try {
            let value = this.formService.getValue();

            let data = (await this.client.post('tourism-bus-routing/booking/fare-search', Object.assign(
                {},
                value,
                {
                    infCount: this.infantCount,
                    adultCount: this.adultCount,
                    childCount: this.childCount,
                    participants: this.participants,
                    order: {
                        id: this.order.id,
                        modelId: this.order.modelId
                    }
                }))).data;

            this.loading = false;

            //Todo error display

            if (!data.searchId) {
                this.flash.error('Es konnte kein Suchergebnis gefunden werden');
            }

            this.searchId = data.searchId;
        } catch (err) {

            this.loading = false;
            this.searchId = null;

            let error = null;

            if (err && err.data) {
                if (err.data.localizedMessage) {
                    error = err.data.localizedMessage;
                } else if (err.data.message) {
                    error = err.data.message;
                }
            }

            this.flash.error(error || 'Ein unbekannter Fehler ist aufgetreten.');
        }
    }

    back() {
        this.searchId = null;
    }

    ok() {
        if (this.selectedItems.length !== 1) {
            this.flash.error('Bitte ein Ergebnis auswählen');
        }

        this.dialogController.ok([{
            id: this.selectedItems[0].id,
            modelId: this.selectedItems[0].modelId,
        }]);
    }
}
